<template>
  <div id="MessageDetail">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="list" v-for="(i,item) in getDocList" :key="item" @click="toLink(i)">
        <div>
          <div v-if="type == 3" class="left" style="background: linear-gradient(156deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);">
            <img style="width:0.14rem" src="../../assets/image/findOut/mess3.png" />
          </div> 
          <div v-else-if="type == 4 && i.subclass == 1" class="left" style="background: linear-gradient(330deg, #E4BF9B 0%, #B58E68 100%);">
            <img style="width:0.11rem" src="../../assets/image/findOut/mess5.png" />
          </div>
          <div v-else-if="type == 4 && i.subclass == 2" class="left" style="background: linear-gradient(143deg, #80DE7D 0%, #6EE686 100%);">
            <img style="width:0.09rem" src="../../assets/image/findOut/mess6.png" />
          </div>
          <div v-else-if="type == 4 && i.subclass == 3" class="left" style="background: linear-gradient(136deg, #FF7E4E 0%, #FFB263 100%);">
            <img style="width:0.11rem" src="../../assets/image/findOut/mess7.png" />
          </div>
          <div>
            <span v-if="type == 3">发布动态审核</span>      
            <span v-else-if="type == 4 && i.subclass == 1">会员到期续费通知</span>
            <span v-else-if="type == 4 && i.subclass == 2">课程购买成功</span>
            <span v-else-if="type == 4 && i.subclass == 3">预约直播成功</span>
            <span>{{Global.clearWhite($moment(new Date(Date.parse(i.created_at))).fromNow())}}</span>
          </div>
        </div>
        <p>{{i.message}}</p>
        <div>
          {{type==3?'重新编辑':'查看详情'}}
          <span>
            <van-icon name="arrow" color="#ECEDF7" />
          </span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

export default {
  name: 'MessageDetail',
  data(){
    return {
      getDocList:[],//公司列表
      finished:false,
      loading:true,
      next_page_url:'',
      type:''
    }
  },
  mounted(){
    this.type = this.$route.query.type
    this.axios({
      method: 'GET',
      url:'/notify/notifyList?type='+this.$route.query.type,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.getDocList = res.data.data.data;
        this.next_page_url = res.data.data.next_page_url
      }
    })
  },
  methods:{
    toLink(sub){
      if(this.type == 4){
        if(sub.subclass == 1){ //会员
          this.$router.push('/MemberProduct')
        }else if(sub.subclass == 2){//课程
          this.$router.push({path:'/CoursesDetail?seriesId='+sub.link_id})
        }else if(sub.subclass == 3){//直播
          this.$router.push({path:'/LiveDetail?id='+sub.link_id})
        }
      }else{   //重新编辑
        this.$router.push({path:'/EditCircle?id='+sub.link_id})
      }
    },
    onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url:this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#MessageDetail{
  .list{
    padding: 0.15rem;
    background: #23252E;
    border-radius: 0.05rem;
    margin-bottom: 0.11rem;
    >div:last-child{
      display: flex;
      justify-content: space-between;
      padding-top: 0.11rem;
      color: #989AB1;
      font-size: 0.12rem;
    }
    >p{
      padding: 0.12rem 0 0.14rem;
      line-height: 0.16rem;
      color: #9091A3;
      font-size: 0.12rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.17);
    }
    >div:first-child{
      display: flex;
      align-items: center;
      >div:first-child{
        width: 0.21rem;
        height: 0.21rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.09rem;
      }
      >div:last-child{
        flex: 1;
        display: flex;
        justify-content: space-between;
        >span:first-child{
          color: #F6F7FD;
        }
        >span:last-child{
          color: #727387;
        }
      }
    }
  }
  padding: 0.15rem;
  background: #16171D;
}
</style>

